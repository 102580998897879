import uuid from "react-uuid";

export function HowWeCanHelpSection(props) {

    const data = props.data;

    return(
        <div className="container black-background color-white" id='how-we-can-help'>
            <div className='content'>
                <div className='content__how-help-section'>
                    <h4 className='services-content__section-title ' 
                        dangerouslySetInnerHTML={{__html: data["how-we-can-help"]["title"]}}>
                    </h4>
                    {
                        <div className='how-we-can-help__container'>
                            {
                                data["how-we-can-help"]["data"].map(el => (
                                    <div key={uuid()} className='how-we-can-help__item'>
                                        <div className='item-content'>
                                            <h4 dangerouslySetInnerHTML={{__html: el["title"]}}></h4>
                                            <p dangerouslySetInnerHTML={{__html: el["text"]}}></p>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}