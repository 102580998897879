import { useState, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { DataLoader, DATA_URL } from '../services/dataLoader';
import { SessionStorageService, SS_KEYS } from '../services/sessionStorage';
import { Layout, MainPage, GetInTouchPage, AboutUsPage, Industries, BlogArticlePage,
         BlogListPage, TemplatePage, VacanciesInfoPage, TermsOfUsePage, PrivacyPolicyPage } from "./index";
import { ScrollToAnchor } from "./scrollToAnchour";
import { ScrollToTop } from "./scrollToTop";
import { useLang } from "../hooks/useLang";


export default function AppRoutes(){

    const [services, setServises] = useState(SessionStorageService.get(SS_KEYS.SERVICES) || null);

    const {currentLanguage} = useLang();

    useEffect(() => {
        const servicesLoader = async () => {
            await DataLoader.set(DATA_URL[`SERVICES_URL_${currentLanguage}`], [SessionStorageService, SS_KEYS.SERVICES]);
            setServises(SessionStorageService.get(SS_KEYS.SERVICES));
        }
        servicesLoader();
    }, []);

    return(
        <>
            <ScrollToAnchor />
            <ScrollToTop />
            <Routes>
                {
                    <Route path="/" element={<Layout />}>
                        <Route index element={<MainPage />}></Route>
                        <Route path="/get-in-touch" element={<GetInTouchPage />}></Route>
                        <Route path="/about-us" element={<AboutUsPage />}></Route>
                        <Route path="/news-and-events" element={<BlogListPage />}></Route>

                        <Route path="/article/:id" element={<BlogArticlePage />}></Route>

                        <Route path="/vacancies-info/:id" element={<VacanciesInfoPage />}></Route>

                        <Route path="/terms-of-use" element={<TermsOfUsePage />}></Route>
                        <Route path="/privacy-policy" element={<PrivacyPolicyPage />}></Route>

                        {
                            services
                            ?
                            Object.keys(services).map((key) => (
                                <Route key={services[key].url} path={services[key].url} element={<TemplatePage data={services[key]} />} ></Route>
                            ))
                            :
                            ""
                        }
                        
                        <Route path="/#Industries" element={<Industries />}></Route>
                        <Route path="/about-us#Careers" element={<AboutUsPage />}></Route>
                        <Route path="/generative-AI#how-we-can-help" element={<AboutUsPage />}></Route>

                    </Route>
                }
            </Routes>
        </>
    );
}