// import { useEffect, useState } from "react";
import { useEffect } from "react";
// import cn from "classnames";
import { useWidth } from "../../../hooks/useWidth";
import { NavItem } from "../navItem";
import { SERVICES } from "../navbarItems";
import "./nav-dropdown.css"

export function NavDropdown(props){

    const {currentWidth} = useWidth();

    const item = props.items;
    const styles = props.styleData;

    useEffect(() => {
        // console.log(currentWidth)
        // const nawItemList = document.querySelectorAll('.nav-item-link');
        // console.log(nawItemList)
    }, [])

    return(
        <div className='dropdown' style={{backgroundColor : styles[0]}}>
            <div className="dropbtn">
                {item.name} 
                {
                    currentWidth <= 992
                    ? <span className="caret" style={{'font-size':'20px'}}>&#x25BA;</span>
                    : <span className="caret">&#x25BC;</span>
                }
                
            </div>
            <div className="dropdown-content" style={{backgroundColor : styles[0]}}>
                {
                    item.name === 'Services'
                    ? SERVICES.map(el => <NavItem key={el.name} items={el} />)
                    // : item.name === 'About us'
                    // ? ABOUT_US.map(el => <NavItem key={el.name} items={el} />)
                    : ''
                }
            </div>
        </div>
        
    );
}