import { GetInTouchForm } from "./getInTouchForm/GetInTouchForm";
import { StandartForm } from "./standartForm"

import './get-in-touch.css'

export function GetInTouch() {
    return(
        <div className="getintouch-section">
            <div className="inner-container">
                <StandartForm />
            </div>
        </div>
    );
}