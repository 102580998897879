import { useEffect, useState } from 'react';
import { useWidth } from '../../../../../hooks/useWidth';
import arrow from '../../../../../assets/down.png'
import cn from 'classnames';
import './faq-item.css'

export function FAQItem(props){

    const {id, question, answer} = props.data;
    const [answerStatusVisible, setAnswerStatusVisible] = useState('');
    const {currentWidth} = useWidth();


    const handleToggleAnswer = () => {
        setAnswerStatusVisible((prevId) => (prevId === id ? '' : id));
    };

    return(
        <div className="faq-item__container">
            {
                currentWidth < 768
                ?
                <div className='question' onClick={handleToggleAnswer}>
                    <p dangerouslySetInnerHTML={{__html: question}}></p>
                    <img src={arrow}  
                        alt='arrow-icon'
                        className={answerStatusVisible === id ? 'active' : ''}/>
                </div>
                :
                <div className='question'>
                    <p dangerouslySetInnerHTML={{__html: question}}></p>
                    <img src={arrow} 
                        onClick={handleToggleAnswer} 
                        alt='arrow-icon'
                        className={answerStatusVisible === id ? 'active' : ''}/>
                </div>
            }
            {
                (answerStatusVisible === id && currentWidth < 768)
                ?
                <div className={cn('answer', {'answer-active' : answerStatusVisible === id})} onClick={handleToggleAnswer} >
                    <p dangerouslySetInnerHTML={{__html: answer}}></p>
                </div>
                :
                answerStatusVisible === id
                ?
                <div className={cn('answer', {'answer-active' : answerStatusVisible === id})}>
                    <p dangerouslySetInnerHTML={{__html: answer}}></p>
                </div>
                :
                ''
            }
        </div>
    );

}