import { useEffect, useState } from "react";
import { LinkWithArrowComponent } from "../../../links";
import { useLang } from "../../../../hooks/useLang";

import flag from '../../../../assets/Flag_of_Ukraine.svg'
import './support.css'


export function Support(){

    const {currentLanguage} = useLang();
    const [sectionText, setSectionText] = useState(['OUR COMMINTENT', 'Be strong, be brave <br/>support Ukraine', 'Donate']);

    useEffect(() => {
        if(currentLanguage === 'EN'){
            setSectionText(['OUR COMMITMENT', 'Be strong, be brave <br/>support Ukraine', 'Donate']);
        }else if(currentLanguage === 'UK'){
            setSectionText(['НАША ВІДПОВІДАЛЬНІСТЬ', 'Будьте сильними, будьте сміливими <br/>підтримайте Україну', 'Допомогти']);
        }
    }, [currentLanguage]);

    const linkData = {"url": "https://savelife.in.ua/en/donate-en/#donate-army-card-monthly", "text": sectionText[2], "color": "#ffffff", "fontSize": "24px"}

    return(
        <div className="support_section">
            <div className="support_main-container main-container">
                <div className="support_description">
                    <h3 className="support_title" dangerouslySetInnerHTML={{__html: sectionText[0]}}></h3>
                    <p className="support_text" dangerouslySetInnerHTML={{__html: sectionText[1]}}></p>
                    <LinkWithArrowComponent data={linkData} />
                </div>
                <div className="support_img-container">
                    <img src={flag} alt="Ukraine-flag"/>
                </div>
            </div>
        </div>
    );
}