const SOCIAL_PAGES = [
    {
        "name" : "facebook",
        "link" : "https://www.facebook.com/profile.php?id=100093037981971&mibextid=ibOpuV/",
        "imgUrl" : "images/social/facebook.png",
        "bw-social-imgUrl" : "images/social/bw/facebook.png",
    },
    {
        "name" : "instagram",
        "link" : "https://www.instagram.com/tenetix.ai/",
        "imgUrl" : "images/social/instagram.png",
        "bw-social-imgUrl" : "images/social/bw/instagram.png",
    },
    {
        "name" : "linkedin",
        "link" : "https://www.linkedin.com/company/tenetix/",
        "imgUrl" : "images/social/linkedin.png",
        "bw-social-imgUrl" : "images/social/bw/linkedin.png",
    }
    // ,
    // {
    //     "name" : "telegram",
    //     "link" : "https://www.telegram.org/",
    //     "imgUrl" : "images/social/telegram.png",
    //     "bw-social-imgUrl" : "images/social/bw/telegram.png",
    // },
    // {
    //     "name" : "github",
    //     "link" : "https://github.com/",
    //     "imgUrl" : "images/social/github.png",
    //     "bw-social-imgUrl" : "images/social/bw/github.png",
    // }
]

export { SOCIAL_PAGES }