
import './about-us.css';
import { TeamItem } from './teamItem';

export function AboutUs(){
    return(
        <div className='container'>
            <div className="about-us">
                <div className='about-us__description-section'>
                    <h2 className="about-us__description-section__title">About TENETIX</h2>
                    <div className='about-us__description-section__text-container'>
                        <p>
                            TENETIX is an innovative company with a pationate team of proffecionals. 
                            Our main goal  is to help our customer refuse routine and become more productive.<br/>
                            We serve different industries, for instance,  logistics, financing, hi-tech, insurance,  
                            startups and science. Today, we cooperate with clients  helping their business get to 
                            the next level.We believe that Ukraine win and all of us  have become stronger, and our 
                            team will help other companies to stay afloat.
                        </p>
                    </div>
                    <div className='about-us__description-section__text-row-section'>
                        <div className='about-us__description-section__text-container'>
                            <h4 className='about-us__description-section__text-title'>Individual Approach</h4>
                            <p>What works well for one business, won't work for you.</p>
                        </div>
                        <div className='about-us__description-section__text-container'>
                            <h4 className='about-us__description-section__text-title'>Professionall Team</h4>
                            <p>Talented people and their experience is our main resource.</p>
                        </div>
                        <div className='about-us__description-section__text-container'>
                            <h4 className='about-us__description-section__text-title'>Building the future now</h4>
                            <p>Embracing new technologies is always tricky, the first step is easier with us</p>
                        </div>
                    </div>
                </div>
                <div className='about-us__second-section'>
                    <div className='about-us__second-section__content'>
                        <h3 className='about-us__second-section__title'>Mision</h3>
                        <p>We are  the dreamers  and innovators, that  always ready  for a new challenges. </p>
                        <p>The main goal of our company is help our clients refuse repetitive tasks and have enough time for creative  activity.</p>
                        <p className='about-us__second-section__text_bold'>Time is the most powerful resource nowadays. And our mission is keep time for you.</p>
                    </div>
                    <div className='about-us__second-section__content'>
                        <h3 className='about-us__second-section__title'>Values</h3>
                        <p className='about-us__second-section__text_bold'>Integrity: We conduct business with the highest  standards of integrity and ethics. Trust and transparency are at the heart of our business relationships, and we uphold these values in all our interactions, ensuring that our clients can rely on us with confidence.</p>
                        <p>Continuous learning: Technology is a rapidly evolving field and we are committed to continuous learning and improvement. We support ongoing professional development, staying abreast of the latest trends and best practices to ensure we are always providing the highest quality services to our clients.</p>
                        <p>Social and environmental responsibility:  We take responsibility for your impact on society and the environment. </p>
                    </div>
                </div>
            </div>
        </div>
    );
}