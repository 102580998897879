
import uuid from 'react-uuid';
import './roadmap.css'
import { RoadmapItem } from './roadmapItem/RoadmapItem';

export function Roadmap(props){
    const data = props.data;
    return(
        <div className="roadmap__container">
            <div className="roadmap__content">
                {data["data"].map(el => (
                    <RoadmapItem key={uuid()} data={el} />
                ))}
            </div>
        </div>
    );
}