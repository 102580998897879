
import cn from 'classnames';
import { LocalStorageService, LS_KEYS } from '../../../services/localStorage';
import { useLang } from '../../../hooks/useLang';
import './language-selector.css'

export function LanguageSelector(props) {

    const {myRef} = props;
    const {currentLanguage, setCurrentLanguage} = useLang();

    const changeActiveLang = (e) => {
        if(e.target.className === 'language__button__active') return;
        const elements = document.getElementsByClassName('language__button__active');
        Array.from(elements).forEach(element => {
            element.classList.remove('language__button__active');
        });
        e.target.classList.add('language__button__active');
        LocalStorageService.set(LS_KEYS.LANG, e.target.value);
        setCurrentLanguage(e.target.value)
        window.location.reload();
    }   

    return(
        <>
            <div className='language__container' ref={myRef}>
                <button className={cn('language__button', {'language__button__active': currentLanguage === 'EN'})} onClick={(e) => changeActiveLang(e)} value="EN">En</button>
                <button className={cn('language__button', {'language__button__active': currentLanguage === 'UK'})} onClick={(e) => changeActiveLang(e)} value="UK">Uk</button>
            </div>
        </>
    );
}