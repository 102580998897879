import { LinkWithArrow } from "./whithArrow/linkWithArrow";

export function LinkWithArrowComponent(props){

    const data = props.data;

    return(
        <>
            <LinkWithArrow data={data} />
        </>
    );
}