import { Simplify, Industries, Support, Blog, Tools, WhoWeAre} from "../components/pages/mainPage";

export function MainPage() {
    return(
        <>
            <Simplify />
            <Industries />
            <Tools />
            <WhoWeAre />
            {/* <Blog /> */}
            <Support />
            {/* <Partners />             */}
            {/* <GetInTouch /> */}
        </>
    )
}