import { Outlet } from "react-router-dom";
import { Footer } from "../components/footer";
import { Header } from "../components/header";

export const Layout = () => {
    return(
    <>
        <Header />
        <main style={{marginTop : '100px'}}>
            <Outlet />
        </main>
        <Footer />
    </>
    );
}