import React from 'react';
import { Link } from 'react-router-dom';
import uuid from 'react-uuid';
import { FAQItem } from './faqItem/FAQItem';
import { TechSwiper } from './swiper';
import { BusinessBenefitsSection } from './businessBenefitsSection';
import { HowWeCanHelpSection } from './howWeCanHelpSection';
import { HowWeDewelopSection } from './howWeDevelopSection';
import './template.css';

export function Template(props){
  
    const data = props.data;
    const backgroundMainImageURL = data["main-image"];

    return(
        <>
        <div className="container">
            <div className='content'>
                <div className="content__title-section" style={{"backgroundImage": `url(${backgroundMainImageURL})`}}>
                    <h2 className='content__title'>{data.title}</h2>
                    <div className='content__title__button-section'>
                        <Link to={`${data.url}#how-we-can-help`} className='content__title-link'>How we can help</Link>
                        <Link to='/get-in-touch' className='content__title-link'>Book a meeting</Link>
                    </div>
                </div>
                {
                    data['main-text-description']
                    ?
                        <div className='content__description-section'>
                            {data['main-text-description'].map(el => (<p key={uuid()} dangerouslySetInnerHTML={{__html: el}}></p>))}
                        </div>
                    :
                    null
                }
            </div>
        </div>
            {
                data["how-we-can-help"]
                ?
                <HowWeCanHelpSection data={data} />
                :
                null
            }
            {
                data["business-benefits"]
                ?
                <BusinessBenefitsSection data={data} />
                :
                null
            }
            {
                data["how-we-develop"] 
                ?
                <HowWeDewelopSection data={data} />
                :
                null
            }
            {
                data["tech-section"]
                ?
                <div className="container max-width zero-padding">
                    <div className='content max-width'>
                        <h3 className='content__title' 
                            dangerouslySetInnerHTML={{__html: data["tech-section"]["title"]}}>
                        </h3>
                        <TechSwiper data={data["tech-section"]["data"]} />
                    </div>
                </div>
                :
                null
            }
            {
                data["faq-section"]
                ?
                <div className="container light-gray-background padding-block-two-percent">
                    <h3 className='content__title' 
                        dangerouslySetInnerHTML={{__html: data["faq-section"]["title"]}}></h3>
                    <div className='content'>
                        {
                            Object.keys(data["faq-section"]["data"]).map(el => (
                                <FAQItem key={uuid()} data={{id : uuid(), question : el, answer : data["faq-section"]["data"][el]}} />
                            ))
                        }
                    </div>
                </div>
                :
                null
            }
        </>
    );
}