import { useEffect, useState } from 'react';
import uuid from 'react-uuid';
import { useLang } from '../../../../hooks/useLang';
import { ToolsItem } from './toolsItem/ToolsItem';
import rpa from '../../../../assets/icons/rpa.png';
import ai from '../../../../assets/icons/ai.png';
import cto from '../../../../assets/icons/artificial-intelligence.png';
import dataAnalicys from '../../../../assets/icons/data-analytics.png';
import './tools.css'

export function Tools(){

    const {currentLanguage} = useLang();

    const [sectionText, setSectionText] = useState([
        ['CTO', 'Our team of pre-vetted, veteran CTO`s will help with tech strategy, team building, R&D management, deep-tech and architectural decision-making.', cto, '01'],
        ['Intelligent Automation', 'Our team of pre-vetted, veteran CTO`s will help with tech strategy, team building, R&D management, deep-tech and architectural decision-making.', rpa, '02'],
        ['Data&Analycis', 'Our team of pre-vetted, veteran CTO`s will help with tech strategy, team building, R&D management, deep-tech and architectural decision-making.', dataAnalicys, '03'],
        ['Generative AI', 'Our team of pre-vetted, veteran CTO`s will help with tech strategy, team building, R&D management, deep-tech and architectural decision-making.', ai, '04']
    ]);

    useEffect(() => {
        if(currentLanguage === 'EN'){
            setSectionText([
                ['CTO', 'Our team of pre-vetted, veteran CTO`s will help with tech strategy, team building, R&D management, deep-tech and architectural decision-making.', cto, '01'],
                ['Intelligent Automation', 'Experienced experts ensure Intelligent Automation triumph through adept tech strategy, team leadership, R&D management, and decisive deep-tech and architectural choices.', rpa, '02'],
                ['Data&Analycis', 'Proficient analysts drive Data & Analytics excellence—strategic insights, team development, research management, and sound decision-making define our curated team`s capabilities.', dataAnalicys, '03'],
                ['Generative AI', 'Our skilled team guides Generative AI endeavors, offering expertise in strategy, team building, research management, and pivotal decision-making for transformative outcomes.', ai, '04']
            ]);
        }else if(currentLanguage === 'UK'){
            setSectionText([
                ['CTO', 'Наша команда CTO, попередньо перевірених і з великим досвідом, допоможе вам у стратегії технологій, формуванні команди, управлінні R&D, впровадженні глибоких технологій та прийнятті архітектурних рішень.', cto, '01'],
                ['Intelligent Automation', 'Наші експерти гарантують автоматизацію завдяки вмілій стратегії, лідерству, управлінню та вирішенню ключових питань у глибоких технологіях і архітектурних виборах.', rpa, '02'],
                ['Data&Analycis', 'Наші аналітики прямують до досконалості у сфері Data&Analytics, надаючи стратегічні інсайти, розвиваючи команду, керуючи дослідженнями та приймаючи обґрунтовані рішення.', dataAnalicys, '03'],
                ['Generative AI', 'Наша команда керує зусиллями у сфері Generative AI, надаючи експерзу у стратегії, формуванні команди, управлінні дослідженнями та ключовому прийнятті рішень для трансформаційних результатів.', ai, '04']
            ]);
        }
    }, [currentLanguage]);

    return(
        <div className="tools-section">
            <div className='tools-main-container main-container'>
                <h2 className='tools-header'>    
                    SERVICES
                </h2>
                <div className='tools-inner-container'>
                    {
                        sectionText.map((el, index) => (
                            index % 2 ===  0 
                            ?
                            <ToolsItem data={el} id={uuid()}/>
                            : 
                            null
                        ))
                    }
                </div>
                <div className='tools-inner-container'>
                    {
                        sectionText.map((el, index) => (
                            index % 2 !==  0 
                            ?
                            <ToolsItem data={el} id={uuid()}/>
                            : 
                            null
                        ))
                    }
                </div>
            </div>
        </div>
    );
}