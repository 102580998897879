import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import { LinkWithBackgroundComponent } from '../links/linkWithBackgroundComponent';
import { useStyle } from '../../hooks/useStyle';
import { useWidth } from '../../hooks/useWidth';
import { useLang } from '../../hooks/useLang';
import { NavItem } from './navItem';
import { LanguageSelector } from './language-selector/LanguageSelector';
import { NAV_ITEMS } from './navbarItems';
import { NavDropdown } from './navDropdown/NavDropdown';
import './header.css';

export function Header() {

    const styles = useStyle();
    const {currentWidth} = useWidth();
    const {currentLanguage} = useLang();
    const [burgerMenuActive, setBurgerMenuActive] = useState(false);
    const [langMenuActive, setLangMenuActive] = useState(false);

    const getInTouchData = {"url": "/get-in-touch", "text": "Contact us", "margin": "5px 15px 5px 5px"}

    const handleSetBurgerMenuActive= (e) => {
        e.stopPropagation();
        setBurgerMenuActive(!burgerMenuActive)
    }

    const handleSetLangMenuActive = (e) => {
        e.stopPropagation();
        setLangMenuActive(!langMenuActive);
    }

    const langMenuRef = useRef(null);
    const menuRef = useRef(null);

    const handleClickOutside = (event) => {
        if (langMenuRef.current && !langMenuRef.current.contains(event.target)) {
            setLangMenuActive(false);
        }
        if (menuRef.current && !menuRef.current.contains(event.target)) {
            setBurgerMenuActive(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside);
        return () => {
        document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        console.log(currentLanguage);
    }, [currentLanguage]);

    return(
        <header style={{backgroundColor : styles[0], color : styles[1]}}>
            <div className='header_main-container main-container'>
                <Link className='header-title-link' to="/" style={{backgroundColor : styles[0], color : styles[1]}}>
                    <h2 className='header-title font-face-fixedsys' style={{borderColor : styles[1]}}>TENETIX</h2>
                </Link>
                {
                    currentWidth <= 992 && !burgerMenuActive 
                    ? ''
                    : <div className='nav-container' ref={menuRef}>
                        <nav className='nav-bar'>
                            <ul className='nav-bar_list'>
                                {
                                    NAV_ITEMS.map(el => {
                                        // return el.name !== 'About us' && el.name !== 'Services'
                                        return el.name !== 'Services'
                                        ? <NavItem key={el.name} items={el} />
                                        : <NavDropdown key={el.name} items={el} styleData={styles} />
                                    })
                                }
                            </ul>
                        </nav>
                        <LinkWithBackgroundComponent data={getInTouchData} />
                        <button className='lang' onClick={(e) => handleSetLangMenuActive(e)} ></button>
                        {
                            langMenuActive
                            ?
                            <LanguageSelector myRef={langMenuRef}/>
                            :
                            null
                        }
                    </div>
                }
                {
                    currentWidth <= 992
                    ?
                    //  <button className={cn('burgermenu-button', {'burger-menu-active':  burgerMenuActive})} 
                    //           onClick={() => setBurgerMenuActive(!burgerMenuActive)}></button>
                              <button className={cn('burgermenu-button', {'burger-menu-active':  burgerMenuActive})} 
                              onClick={(e) => handleSetBurgerMenuActive(e)}></button>
                    : ''
                }
            </div>
        </header>
    );
}