import { LinkWithBackgroundComponent } from '../../../links/linkWithBackgroundComponent';
import { useLang } from '../../../../hooks/useLang';
import { useEffect, useState } from 'react';
// import simp from '../../../../assets/simplify.png';
import simp from '../../../../assets/brain2.svg';
import './simplify.css';

export function Simplify(){

    const {currentLanguage} = useLang();
    const [buttonText, setButtonText] = useState('Free consultation');
    const [sectionText, setSectionText] = useState([]);

    useEffect(() => {
        if (currentLanguage === 'EN'){
            setButtonText('Free consultation');
            setSectionText(['Simplify Your Work<span class="color-blue">.</span>', 'We help simplify your work Using <br/> Artificial intelligence'])
        } else if (currentLanguage === 'UK') {
            setButtonText('Безкоштовна консультація');
            setSectionText(['Simplify Your Work<span class="color-blue">.</span>', 'Ми допомагаємо спростити вашу роботу за допомогою <br/> Штучного інтелекту'])
        }
    }, [currentLanguage]);

    const getInTouchData = {"url": "/get-in-touch", "text": buttonText, "margin": "5px 0px 5px 5px"}

    return(
        <div className="simplify-section">
            <div className='simplify-main-container main-container' style={{backgroundImage:simp}}>
                <div className='simplify-text-container'>
                    <h2 dangerouslySetInnerHTML={{__html: sectionText[0]}}></h2>
                    <p className='simplify-description_text' dangerouslySetInnerHTML={{__html: sectionText[1]}}></p>
                    <LinkWithBackgroundComponent data={getInTouchData} />
                </div>
                <div className='simplify-img-container'>
                    <img src={simp} alt='brain'/>
                </div>
            </div>
        </div>
    );
}