import uuid from "react-uuid";
import "./bussiness-benefits-section.css"



export function BusinessBenefitsSection(props){
    
    const data = props.data;

    return(
        <div className="container"> 
            <div className='content'>
                <div className='content__benefits-section'>
                    <h4 className='services-content__section-title' 
                        dangerouslySetInnerHTML={{__html: data["business-benefits"]["title"]}}>
                    </h4>
                    {
                        <div className='benefits__container'>
                            {
                                data["business-benefits"]["data"].map(el => (
                                    <div key={uuid()} className='benefits__item'>
                                        <h4 className="benefits__item-title" dangerouslySetInnerHTML={{__html: el["title"]}}></h4>
                                        <div className="benefits__item-img-container">
                                            <img className="benefits__item-img" src={el["image-url"]} alt={el["image-alt"]}/>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}