import React from "react";
import { Roadmap } from "./roadmap";


export function HowWeDewelopSection(props){

    const data = props.data;

    return(
        <div className="container black-background color-white"> 
                    <div className='content black-background'>
                        <div className='content__how-develop-section'>
                            <h3 className='content__how-develop__title' 
                                dangerouslySetInnerHTML={{__html: data["how-we-develop"]["title"]}}>
                            </h3>
                            {
                                data["how-we-develop"]["second-title"]
                                ?
                                <h4 dangerouslySetInnerHTML={{__html: data["how-we-develop"]["second-title"]}}></h4>
                                :
                                null
                            }
                            <div className='content__how-develop__content'>                               
                                <Roadmap data={data["how-we-develop"]} />
                            </div>
                        </div>
                    </div>
                </div>
    );
}