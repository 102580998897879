

export function ToolsItem(props){

    const [title, text, image, number] = props.data;

    return(
        <div className='tools-inner-container__content-container'>
            <div className='tools-inner-container__content-container__header-container'>
                <div className='tools-img-container'>
                    <img src={image} alt={title}></img>
                </div>
                <p>{number}</p>
            </div>
            <div className='tools-inner-container__content-container__text-container'>
                <h4 className='tools-inner-container__content-container__text-container__header'
                    dangerouslySetInnerHTML={{__html: title}}></h4>
                <p className='tools-inner-container__content-container__text-container__text'
                    dangerouslySetInnerHTML={{__html: text}}></p>
            </div>
        </div>
    )

}