
import { useState, useEffect } from 'react';
import { DATA_URL, DataLoader } from '../../../../services/dataLoader';
import { SessionStorageService, SS_KEYS } from '../../../../services/sessionStorage';
import { IndustryItem } from './industry-item';
import './industries.css'
import { useLang } from '../../../../hooks/useLang';

export function Industries(){

    const {currentLanguage} = useLang();

    const [sectionText, setSectionText] = useState(['INDUSTRIES', 'Find a solution for your industry']);

    useEffect(() => {
        if(currentLanguage === 'EN'){
            setSectionText(['INDUSTRIES', 'Find a solution for your industry']);
        }else if(currentLanguage === 'UK'){
            setSectionText(['INDUSTRIES', 'Знайдіть рішення для вашої галузі']);
        }
    }, [currentLanguage])

    const [industries, setIndustries] = useState(SessionStorageService.get(SS_KEYS.INDUSTRIES_LIST) || null);
    useEffect(() => {
        const industryLoader = async () => {
            await DataLoader.set(DATA_URL.INDUSTRIES_URL, [SessionStorageService, SS_KEYS.INDUSTRIES_LIST]);
            setIndustries(SessionStorageService.get(SS_KEYS.INDUSTRIES_LIST));
        }
        industryLoader();
    }, []);

    return(
        <div className='industries-section' id='Industries'>
            <div className='industries-main-container main-container'>
                <h2 className='industries-title' dangerouslySetInnerHTML={{__html: sectionText[0]}}></h2>
                <h5 className='industries-description' dangerouslySetInnerHTML={{__html: sectionText[1]}}></h5>
                <div className='industries_item-container'>
                    {
                        industries
                        ? industries.map(el => <IndustryItem key={el.industries_id} data={el} />)
                        : null
                    } 
                </div>
            </div>
        </div>
    );
}