const SS_KEYS = {
    BLOG_LIST : "blogs",
    ARTICLE : "article",
    INDUSTRIES_LIST : "industries",
    PARTNERS_LIST : "partners",
    CUSTOMERS_LIST : "customers",
    ACTIVE_PARTNERS_SECTION : "partners_section",
    LEADERSHIP_LIST : "leadership_list",
    DEVELOPER_LIST : "developer_list",
    SERVICES : "services_data",
    VACANCIES_LIST : "vacancies_list",
    VACANCIES_INFO : "vacancies_info",
    // VACANCIES : "vacancies",
    PRIVACY_DATA : "privacy",
    TERM_OF_USE_DATA : "terms-of-use"
}

class SessionStorageService {

    static get(key){
        const value = window.sessionStorage.getItem(key);
        try {
            return JSON.parse(value);
        } catch {
            return value;
        }
    }

    static set(key,value){
        return window.sessionStorage.setItem(key, JSON.stringify(value));
    }

    static remove(key){
        return window.sessionStorage.removeItem(key);
    }

    static clear(){
        return window.sessionStorage.clear();
    }

}

export { SessionStorageService, SS_KEYS }