
import { useState, useEffect } from 'react';
import { DataLoader, DATA_URL } from '../../../services/dataLoader';
import { SessionStorageService, SS_KEYS } from '../../../services/sessionStorage';
import { BlogItem } from './blogItem';
import titleImage2 from '../../../assets/events-title-bg.jpg';
import './blog-list.css';

export function BlogList() {

    const [blogList, setBlogList] = useState(SessionStorageService.get(SS_KEYS.BLOG_LIST) || null);
    const [sortedBlogList, setSortedBlogList] = useState([] || null);
    const [filteredBlogList, setFilteredBlogList] = useState([] || null);
    const [activeButton, setActiveButton] = useState('All');

    const changeActiveButton = (e) => {
        if(e.target.className === 'blog-list__menu-button__active') return;
        const elements = document.getElementsByClassName('blog-list__menu-button__active');
        Array.from(elements).forEach(element => {
            element.classList.remove('blog-list__menu-button__active');
        });
        e.target.classList.add('blog-list__menu-button__active');
        setActiveButton(e.target.textContent)
    }

    useEffect(() => {
        const blogListLoader = async () => {
            await DataLoader.set(DATA_URL.ARTICLE_URL, [SessionStorageService, SS_KEYS.BLOG_LIST]);
            setBlogList(SessionStorageService.get(SS_KEYS.BLOG_LIST));
        }
        blogListLoader();
    }, []);

    useEffect(() => {
        if(blogList){
            const sortDataByDatePublication = (jsonData) => {
                const datePublication = jsonData['header-section']['date-publication'];
                return {
                    ...jsonData,
                    datePublication: datePublication ? new Date(datePublication.split('.').reverse().join('-')) : null
                };
            }
    
            const sortedArray = [...blogList];
    
            sortedArray.sort((a, b) => {
                const dateA = sortDataByDatePublication(a).datePublication;
                const dateB = sortDataByDatePublication(b).datePublication;
                if (!dateA && !dateB) {
                    return 0;
                } else if (!dateA) {
                    return 1;
                } else if (!dateB) {
                    return -1;
                } else {
                    return dateB - dateA;
                }
            });
            setSortedBlogList(sortedArray);
        }
    }, [blogList]);

    useEffect(() => {
        if(blogList){
            const filteredBlogListArray = [...sortedBlogList];

            const filteredBlogList = filteredBlogListArray.filter(item => {
                if (activeButton === 'All') {
                    return true;
                } else if (activeButton === 'News') {
                    return item['header-section']['category'] === 'blog';
                } else if (activeButton === 'Events') {
                    return item['header-section']['category'] === 'event';
                }
            });

            setFilteredBlogList(filteredBlogList);
        }
    },[sortedBlogList, activeButton])

    return(
        <div className='padding-container'>
            <section className='blog-list'>
                <h2 style={{backgroundImage: `url(${titleImage2})`, backgroundSize: "cover"}} className='blog-list__title'>TENETX`s News & Events</h2>
                <div className='blog-list__event-navigation-menu'>
                    <button onClick={(e) => changeActiveButton(e)} className='blog-list__menu-button blog-list__menu-button__active'>All</button>
                    <button onClick={(e) => changeActiveButton(e)} className='blog-list__menu-button'>News</button>
                    <button onClick={(e) => changeActiveButton(e)} className='blog-list__menu-button'>Events</button>
                </div>   
                <div className='blog-list__articles-container'>
                    {
                        filteredBlogList
                        ?
                        // sortedBlogList.map(el => <BlogItem key={el["_id"]} data={el} />)
                        filteredBlogList.map(el => (<BlogItem key={el["_id"]} data={el} />))
                        :
                        ""
                    }
                </div>
            </section>
        </div>
    );
}