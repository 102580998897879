import { useEffect, useState } from 'react';
import { HashRouter } from 'react-router-dom';
import { WidthProvider } from './hooks/useWidth';
import AppRoutes from './routes/router';
import './App.css';
import './styles/main-container/main-container.css'
import { LS_KEYS, LocalStorageService } from './services/localStorage';
import { LangProvider } from './hooks/useLang';

function App() {

  const [currentWidth, setCurrentWidth] = useState(window.innerWidth);
  const [currentLanguage, setCurrentLanguage] = useState(LocalStorageService.get(LS_KEYS.LANG) || 'UK');

  const resizeWindow = () => {
    setCurrentWidth(window.innerWidth);
  };
  
  useEffect(() => {
    window.addEventListener('resize', resizeWindow);
    // console.log(currentWidth);
    return () => window.removeEventListener('resize', resizeWindow);
  }, [currentWidth])

  return (
    <HashRouter basename='/'>
      <WidthProvider value={{currentWidth}}>
        <LangProvider value={{currentLanguage, setCurrentLanguage}}>
          <div className="App">
            <AppRoutes />
          </div>
        </LangProvider>
      </WidthProvider>
    </HashRouter>
  );
}

export default App;
