import React, { useEffect, useState } from "react";
import { Link, useParams  } from "react-router-dom";
import { SS_KEYS, SessionStorageService } from "../../../services/sessionStorage";
import { DATA_URL, DataLoader } from "../../../services/dataLoader";
import uuid from "react-uuid";
import "./blog-article.css";

export function BlogArticle() {

    const [blogList, setBlogList] = useState(SessionStorageService.get(SS_KEYS.BLOG_LIST) || null);
    const [blogArticle, setBlogArticle] = useState(SessionStorageService.get(SS_KEYS.ARTICLE) || null);
    const id = useParams();

    useEffect(() => {
        if(!blogList){
            const blogListLoader = async () => {
                await DataLoader.set(DATA_URL.ARTICLE_URL, [SessionStorageService, SS_KEYS.BLOG_LIST]);
                setBlogList(SessionStorageService.get(SS_KEYS.BLOG_LIST));
            }
            blogListLoader();
        }
    }, []);

    useEffect(() => {
        if(blogList){
            const getBlogArticleById = (id) => {
                return blogList.find(article => article['_id'] === id.id);
            };
            setBlogArticle(getBlogArticleById(id));
        }
    }, [id, blogList]);

    return(
        <div className="blog-article_container">
        {
            blogArticle ?
            <div className="main-container">
                <div className="blog-article_header">
                    <div className="blog-author-info">
                        <img className="blog-author-info_photo" 
                                 src={blogArticle["header-section"]["author-avatar-url"]} 
                                 alt={blogArticle["header-section"]["author-avatar-alt"]}></img>
                        <div className="blog-author-info_info-container">
                            <p dangerouslySetInnerHTML={{__html: blogArticle["header-section"]["author"]}}></p>
                            <div className="blog-author-info_info-container__row" >
                                <p dangerouslySetInnerHTML={{__html: blogArticle["header-section"]["date-publication"]}}></p>
                                <p dangerouslySetInnerHTML={{__html: blogArticle["header-section"]["read-time"]}}></p>
                            </div>
                        </div>
                    </div>
                    <Link to="/news-and-events">
                            &larr; До всіх статей
                    </Link>
                </div>
                <h3 className="blog-article-title"
                        dangerouslySetInnerHTML={{__html: blogArticle["header-section"]["main-title"]}}>
                </h3>
                <div className="blog-article_article-container">
                    <img className="blog-image" 
                         src={blogArticle["header-section"]["big-image"]} 
                         alt={blogArticle["header-section"]["image-alt-text"]}></img>
                    <div className="blog-text">
                        {
                            blogArticle["main-section"].map(el => (
                            (typeof(el) === "string") 
                            ?
                            <p key={uuid()} dangerouslySetInnerHTML={{__html: el}}></p>
                            :
                            (typeof(el) === "object" && Array.isArray(el))  
                            ?
                            el.map(newEl => (
                                <p key={uuid()} dangerouslySetInnerHTML={{__html: newEl}}></p>
                            ))
                            :
                            (typeof(el) === "object")
                            ?
                            Object.keys(el).map(oblEl => (
                                <React.Fragment key={uuid()}>
                                    <h3 dangerouslySetInnerHTML={{__html: oblEl}}></h3>
                                    <p dangerouslySetInnerHTML={{__html: el[oblEl]}}></p>
                                </React.Fragment>
                            ))
                            : null
                            ))
                        }
                        {
                            <>
                                {   blogArticle["conclusion-section"]["conclusion-title"]
                                    ?
                                    <h3 className="blog-text__inference" dangerouslySetInnerHTML={{__html: blogArticle["conclusion-section"]["conclusion-title"]}}></h3>
                                    :
                                    null
                                }
                                {
                                    blogArticle["conclusion-section"]["conclusion-text"].map(el => (
                                        (typeof(el) === "string") 
                                        ?
                                        <p key={uuid()} className="blog-text__inference" dangerouslySetInnerHTML={{__html: el}}></p>
                                        :
                                        "   "
                                    ))
                                }
                            </>
                        }
                    </div>
                </div>
            </div>
            :
            ""
        }
        </div>
    );
}