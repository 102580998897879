import { Link } from 'react-router-dom';

import './link-with-arrow.css';


export function LinkWithArrow(props){

    const data = props.data;
    const {url, text, color="", fontSize="", margin="", padding=""} = data;

    const linkStyle = {
        "color" : color,
        "fontSize" : fontSize,
        "margin" : margin,
        "padding" : padding,
        '&:hover, &:focus, &:active, &:visited': {
            "color" : color
        }
    }

    return(
        <>
            <Link to={url} className='link-with-arrow' style={linkStyle}>
                {text} &rarr;
            </Link>
        </>
    );

}