import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useLang } from '../../../../hooks/useLang';

import cat from '../../../../assets/whoWeAre/cat1.jpg'
import cat2 from '../../../../assets/whoWeAre/cat2.jpg'
import cat3 from '../../../../assets/whoWeAre/cat3.jpeg'
import colorface from '../../../../assets/whoWeAre/colorface.jpg'
import computer1 from '../../../../assets/whoWeAre/computer1.jpg'
import computer2 from '../../../../assets/whoWeAre/computer2.jpg'
import heart from '../../../../assets/whoWeAre/heart.jpg'
import microchip1 from '../../../../assets/whoWeAre/microchip1.jpg'
import microchip2 from '../../../../assets/whoWeAre/microchip2.jpg'
import milkway from '../../../../assets/whoWeAre/milkway.png'
import person1 from '../../../../assets/whoWeAre/person1.jpg'
import person2 from '../../../../assets/whoWeAre/person2.jpg'
import road from '../../../../assets/whoWeAre/road.jpg'
import success1 from '../../../../assets/whoWeAre/bulb.jpg'
import success2 from '../../../../assets/whoWeAre/success2.jpg'
import team1 from '../../../../assets/whoWeAre/team1.jpg'
import team2 from '../../../../assets/whoWeAre/team2.jpg'
import team3 from '../../../../assets/whoWeAre/team3.jpg'
import './whoWeAre.css'

export function WhoWeAre(){

    const {currentLanguage} = useLang();

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const [sectionText, setSectionText] = useState(['WHO WE ARE', 
            'We believe that the cooperation of humans and AI</br>can cope with any global challenges',
            'More about us']);

    useEffect(() => {
        if(currentLanguage === 'EN'){
            setSectionText(['WHO WE ARE', 
            'We believe that the cooperation of humans and AI</br>can cope with any global challenges',
            'More about us']);
        }else if(currentLanguage === 'UK'){
            setSectionText(['ХТО МИ', 
            'Ми віримо, що співпраця людей і ШІ<br/>може впоратися з будь-якими глобальними викликами',
            'Більше про нас']);
        }        
    }, [currentLanguage]);

    useEffect(() => {
        const handleResize = () => {
          setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => {
        window.removeEventListener('resize', handleResize);
        };
    }, []);


    return(
        <div className="whoWeAre-section ">
            <div className='main-container whoWeAre-main-container'>
                <h2 dangerouslySetInnerHTML={{__html: sectionText[0]}}></h2>
                <p dangerouslySetInnerHTML={{__html: sectionText[1]}}></p>
                <Link to="/about-us">{sectionText[2]} &rarr;</Link>
            </div>
            <div className='grid-container'>
                <div className='item1 grid-item'><img src={team1} alt=''/></div>
                <div className='item grid-item'><img src={team3} alt=''/></div>
                <div className='item5 grid-item'><img src={heart} alt=''/></div>
                <div className='item grid-item'><img src={success2} alt=''/></div>
                {
                    windowWidth > 768 ?  <>
                        <div className='item grid-item'><img src={success1} alt=''/></div>
                        <div className='item grid-item'><img src={colorface} alt=''/></div>
                        <div className='item2 grid-item'><img src={computer1} alt=''/></div>
                        <div className='item grid-item'><img src={cat} alt=''/></div>
                        <div className='item grid-item'><img src={cat2} alt=''/></div>
                        <div className='item6 grid-item'><img src={road} alt=''/></div>
                        <div className='item grid-item'><img src={computer2} alt=''/></div>
                    </> : ''
                }
                {
                    windowWidth > 1200 ?  <>
                        <div className='item3 grid-item'><img src={person1} alt=''/></div>
                        <div className='item8 grid-item'><img src={milkway} alt=''/></div>
                        <div className='item grid-item'><img src={microchip1} alt=''/></div>
                        <div className='item grid-item'><img src={team2} alt=''/></div>
                        <div className='item4 grid-item'><img src={person2} alt=''/></div>
                        <div className='item7 grid-item'><img src={cat3} alt=''/></div>
                        <div className='item grid-item'><img src={microchip2} alt=''/></div>
                    </> : ''
                }
            </div>
        </div>
    );
}