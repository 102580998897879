import uuid from 'react-uuid';
import './roadmap-item.css'

export function RoadmapItem(props){

    const data = props.data;

    return(
        <div className="roadmap__row">
            
            <div className="roadmap__left-cell">
                {
                    (data[2]%2) !== 0 
                    ?
                    <>
                        <h4>{data[0]}</h4>
                        {
                            data[1].length === 1
                            ? 
                            <p  dangerouslySetInnerHTML={{__html: data[1]}}></p>
                            :
                            data[1].length > 1
                            ?
                            data[1].map(el => (
                                <p key={uuid()} dangerouslySetInnerHTML={{__html: el}}></p>
                            ))
                            :
                            null
                        }
                        {/* <p dangerouslySetInnerHTML={{__html: data[1]}}></p> */}
                    </>
                    :
                    null
                }
            </div>
            <div className="roadmap__right-cell">
                {
                    (data[2]%2) === 0 
                    ?
                    <>
                        <h4>{data[0]}</h4>
                        {
                            data[1].length === 1
                            ? 
                            <p dangerouslySetInnerHTML={{__html: data[1]}}></p>
                            :
                            data[1].length > 1
                            ?
                            data[1].map(el => (
                                <p key={uuid()} dangerouslySetInnerHTML={{__html: el}}></p>
                            ))
                            :
                            null
                        }
                        {/* <p dangerouslySetInnerHTML={{__html: data[1]}}></p> */}
                    </>
                    :
                    null
                }
            </div>
            <div className='roadmap__step-number'>{data[2]}</div>
        </div>
    );
}