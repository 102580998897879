import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import uuid from 'react-uuid';

import { SS_KEYS, SessionStorageService } from '../../../services/sessionStorage';
import { DATA_URL, DataLoader } from '../../../services/dataLoader';
import aiml from '../../../assets/vacancies/AI_ML.svg';

import './vacancies.css';

export function Vacancies() {

    const [vacanciesData, setVacanciesData] = useState(SessionStorageService.get(SS_KEYS.VACANCIES_LIST) || null);

    useEffect(() => {
        const vacanciesDataLoader = async () => {
            await DataLoader.set(DATA_URL.VACANCIES_LIST_URL, [SessionStorageService, SS_KEYS.VACANCIES_LIST]);
            setVacanciesData(SessionStorageService.get(SS_KEYS.VACANCIES_LIST));
        }
        vacanciesDataLoader();
    }, []);
    
    return(
        <section className='vacancies_container' id="Careers">
            <div className='vacancies_section main-container'>
                <h3 className='vacancies_header-title'>
                    VACANCIES
                </h3>
                <h2 className='vacancies_main-title'>
                    Join our team
                </h2>
                <br/>
                <p className='vacancies_description-text'>We're always happy to see new professionals in our teams!</p>
                <div className='vacancies_item-container'>
                    {
                        vacanciesData
                        ?
                        vacanciesData.map((el) => (
                            <div className='vacancies_single-item-container' key={uuid()}>
                                <div className='vacancies_item'>
                                    <div className='vacancies_item-image-container'>
                                        <img src={aiml} className='vacancies_item-image' alt={el.title}/>
                                    </div>
                                    <div className='vacancies_item-title' dangerouslySetInnerHTML={{__html: el.title}}></div>
                                </div>
                                <Link className='vacancies_link' to={`/vacancies-info/${el._id}`}>About Vacancy</Link>
                            </div>
                        ))  
                        :
                        null
                    }
                </div>
            </div>
        </section>
    );
}
