import { AboutUs } from "../components/pages/aboutUs"; 
import { Vacancies } from "../components/pages/vacancies";

export function AboutUsPage(){

    return(
        <>
            <AboutUs />
            <Vacancies/>
        </>
    );
}