import { Link } from "react-router-dom";



export function IndustryItem(props) {

    const data = props.data;

    const backgroundImageStyle = {
        background: `url(images/industries/${data["image-url"]})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: '100%',
        width: '100%',
        overflow: 'hidden',
    };

    if(data["industries_id"] > 6) return;

    return(
        <div className='industries_item'>
            <div className="item_image" style={backgroundImageStyle}>
            </div>
            <h3 className="industries_item-title">{data["title"]}</h3>
        </div>
    );
}