import { TermsOfUse } from "../components/pages/additionalPages/termsOfUse";


export function TermsOfUsePage(){

    return(
        <>
            <TermsOfUse />
        </>
    );
}