import React, { useContext, useEffect, useState } from 'react';
import { Virtual } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation } from 'swiper/modules';
import { useWidth } from '../../../../../hooks/useWidth'

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import './tech-swiper.css';

export function TechSwiper(props) {

  const data = props.data;
  const {currentWidth} = useWidth();

  const [slidesCountView, setSlidesCountView] = useState(5);

  useEffect(() => {
    (currentWidth > 1000)
    ? setSlidesCountView(5) 
    :((currentWidth < 1000)&&(currentWidth > 600))
    ? setSlidesCountView(4)
    : setSlidesCountView(3)
  }, [currentWidth]);

    return (
      <>
        <Swiper
          observer={true}
          observeParents={true}
          slidesPerView={slidesCountView}
          spaceBetween={30}
          loop={true}
          // pagination={{
          //   clickable: true,
          // }}
          navigation={true}
          modules={[Pagination, Navigation, Virtual]}
          className="mySwiper"
        >
          {
            data.map((el, index) => (
              <SwiperSlide key={index} virtualIndex={index}><img src={el} alt={el}/></SwiperSlide>
            ))
          }
        </Swiper>
      </>
    );
  }