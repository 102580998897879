const DATA_URL = {
                  //Ukraine
                  VACANCIES_LIST_URL : "/data/vacancies/vacancies.JSON",
                  ARTICLE_URL : "/data/newsAndEvents/newsAndEvents.JSON",
                  INDUSTRIES_URL : "/data/UK/industry.JSON",
                  SERVICES_URL_UK : "/data/UK/services.JSON",
                  PRIVACY_URL_UK : "/data/UK/PP.JSON",
                  TERM_OF_USE_URL_UK : "/data/UK/TermsOfUse.JSON",

                  //English
                  SERVICES_URL_EN : "/data/EN/services.JSON",
                  PRIVACY_URL_EN : "/data/EN/PP.JSON",
                  TERM_OF_USE_URL_EN : "/data/EN/TermsOfUse.JSON"
                };

class DataLoader{
    static async set(path, storage){
        return fetch(path)
        .then((response) => response.json())
        .then((data) => storage[0].set(storage[1], data))
        .catch((e) => alert(`Sorry, services dosn't work ${e}`))
    }
}

export { DataLoader, DATA_URL }